import gql from 'graphql-tag';

export const InlineCommentQuery = gql`
	query InlineCommentQuery(
		$annotationId: String
		$pageId: ID!
		$contentStatus: [GraphQLContentStatus]
	) {
		comments(inlineMarkerRef: $annotationId, pageId: $pageId, contentStatus: $contentStatus) {
			nodes {
				replies {
					id
					parentId
					body(representation: ATLAS_DOC_FORMAT) {
						value
					}
					createdAt {
						value
					}
					links {
						webui
						editui
					}
					author {
						displayName
						permissionType
						profilePicture {
							path
						}
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
					location {
						type
					}
					version {
						when
						number
					}
					permissions {
						isEditable
						isRemovable
						isResolvable
					}
					reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
						reactionsCount
						ari
						containerAri
						reactionsSummaryForEmoji {
							emojiId
							count
							reacted
							id
						}
					}
				}
				version {
					when
					number
				}
				location {
					type
					... on InlineComment {
						inlineMarkerRef
						inlineText
						inlineResolveProperties {
							resolved
							resolvedTime
							resolvedUser
						}
					}
				}
				id
				body(representation: ATLAS_DOC_FORMAT) {
					value
				}
				createdAt {
					value
				}
				links {
					webui
					editui
				}
				author {
					displayName
					profilePicture {
						path
					}
					permissionType
					... on KnownUser {
						accountId
					}
					... on User {
						accountId
					}
				}
				permissions {
					isEditable
					isRemovable
					isResolvable
				}
				reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
					reactionsCount
					ari
					containerAri
					reactionsSummaryForEmoji {
						emojiId
						count
						reacted
						id
					}
				}
			}
		}
	}
`;
